<template>
  <section>
    <div id="pdf-content"  v-if="dataItem !== null">
      <section id="surat">
        <div class="row mb-2">
          <div class="col-11 offset-1">
            <div class="row" id="kop">
              <div class="col-2 bb">
                <img src="@/assets/logo/logo_surat.png" alt="" />
              </div>
              <div class="col-10 bb">
                <h3 id="judul">
                  PEMERINTAH KABUPATEN BANDUNG <br />
                  DINAS PEKERJAAN UMUM DAN TATA RUANG <br />
                  UPTD PENERANGAN JALAN UMUM <br />
                </h3>
                <p id="alamat">
                  Jl. RAYA SOREANG-BANJARAN KM. 3 SOREANG TELP. 5892773-5892580 –
                  SOREANG
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="halaman">
          <div class="col">
            <p
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 15px;
                font-weight: bold;
                overflow: hidden;
                text-decoration: underline;
              "
            >
            LAPORAN KEGIATAN PEMELIHARAAN PJU
            </p>

            <div id="content">
              <p style="text-align: center">
                Nomor : <span>{{ dataPenugasan.no_assignment }}</span>
              </p>
              <br />
              <center v-if="isAssignmentLoading">
                <br />
                <br />
                <vue-loading></vue-loading>
              </center>
              <br />
              <template v-if="!isAssignmentLoading">
                <p>
                  Tanggal : <span>{{ $moment(dataPenugasan.assignment_date).format("D MMMM YYYY") }}</span>
                </p>
                <p>Catatan Perbaikan : {{ dataPenugasan.remark }}</p>
                
              </template>
              <template style="font-size: 8pt;">
                <div>
                  <div v-for="(dataComplaint, index) in dataPenugasan.assignment_complaints" :key="index">
                    <p>{{ index + 1 }}, {{ dataComplaint.complaint.address }}</p>
                    <div>
                      <!-- First Table -->
                      <table style="width: 100%;">
                        <thead>
                          <tr class="text-center">
                            <th class="border-set" rowspan="2">Penyebab</th>
                            <th class="border-set" rowspan="2">Tindakan</th>
                            <th class="border-set" rowspan="2">Jumlah TC</th>
                            <th colspan="3" class="border-set">Barang Yang Dipakai</th>
                          </tr>
                          <tr>
                            <th class="border-set" rowspan="2">Jenis Komponen</th>
                            <th class="border-set" rowspan="2">Vol</th>
                            <th class="border-set" rowspan="2">Satuan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="border-set">{{ dataComplaint.reason }}</td>
                            <td class="border-set">{{ dataComplaint.action }}</td>
                            <td class="border-set text-center">{{ dataComplaint.total_tc !== null ? dataComplaint.total_tc : '0' }}</td>
                            <td class="border-set" v-if="dataComplaint.complaint.item_usage">
                              <div v-for="detail, indexN in dataComplaint.complaint.item_usage.item_usage_details" :key="indexN">
                                    {{ indexN + 1 }}. {{ detail.item.name !== null ? detail.item.name : '-' }}
                              </div>
                            </td>
                            <td v-else>-</td>
                            <td class="border-set" v-if="dataComplaint.complaint.item_usage">
                              <div v-for="detail, indexN in dataComplaint.complaint.item_usage.item_usage_details" :key="indexN">
                                    {{ detail.qty !== null ? detail.qty : '-' }}
                              </div>
                            </td>
                            <td v-else>-</td>
                            <td class="border-set" v-if="dataComplaint.complaint.item_usage">
                              <div v-for="detail, indexN in dataComplaint.complaint.item_usage.item_usage_details" :key="indexN">
                                    {{ detail.item.unit !== null ? detail.item.unit : '-' }}
                              </div>
                            </td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                      </table>
                      
                      <!-- Second Table -->
                      <!-- <table>
                        <thead>
                          <tr class="text-center">
                            <th colspan="3" class="border-set">Barang Yang Dipakai</th>
                          </tr>
                          <tr class="text-center">
                            <th class="border-set">Jenis Komponen</th>
                            <th class="border-set">Vol</th>
                            <th class="border-set">Satuan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="dataComplaint.complaint.item_usage">
                            <tr class="text-center" v-for="detail in dataComplaint.complaint.item_usage.item_usage_details" :key="detail.id">
                              <td class="border-set">{{ detail.item.name }}</td>
                              <td class="border-set">{{ detail.qty }}</td>
                              <td class="border-set">{{ detail.item.unit }}</td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr class="text-center">
                              <td class="border-set">-</td>
                              <td class="border-set">-</td>
                              <td class="border-set">-</td>
                            </tr>
                          </template>
                        </tbody>
                      </table> -->
                    </div>
                  </div>
                </div>
              </template>

              <p>
                Mengetahui
              </p>
              <br />
              <!-- <p style="text-align: center">( &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;)</p> -->
              <div class="row" style="font-size: 8pt;">
                <div class="col-3 text-center">
                  <p style="font-weight: bold">KEPALA UPTD PJU</p>
                  <br /><br />
                  <p style="font-weight: bold">
                    <span style="text-decoration: underline"
                      >FAHRIZAL SOPYAN, ST, M.AP</span
                    ><br />NIP. 19790424 200604 1 012
                  </p>
                </div>
                <div class="col-3 text-center">
                  <p style="font-weight: bold">KASUBAG TU UPTD PJU</p>
                  <br /><br />
                  <p style="font-weight: bold">
                    <span style="text-decoration: underline"
                      >AHMAD GEOHANSA,ST., M.Tr.AP</span
                    ><br />NIP. 19850103 201101 1 006
                  </p>
                </div>
                <div class="col-3 text-center">
                  <p style="font-weight: bold">PENGAWAS KELISTRIKAN</p>
                  <br /><br /><br />
                  <p style="text-align: center">( &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;)</p>
                </div>
                <div class="col-3 text-center">
                  <p style="font-weight: bold">PEMELIHARA PENERANGAN JALAN</p>
                  <br /><br />
                  <p style="text-align: center">( &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <p>Silahkan Simpan Pembuatan Laporan Terlebih Dahulu</p>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      id : null,
      dataPenugasan: null,
      dataItem: null,
      isAssignmentLoading: true,
      isLoading: true,
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      this.$store.dispatch("assignment/detailAssignment", this.id).then((resp) => {
        loading.hide();
        this.dataPenugasan = resp.data.data;
        this.dataItem =this.dataPenugasan.item_usage;
        console.log(this.dataPenugasan)
        setTimeout(() => {
          this.isAssignmentLoading = false;
          this.isLoading = false;
        }, 2000);
        setTimeout(function () {
          window.print();
        }, 5000);
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
  },
  computed: {
    computedData() {
      return this.dataPenugasan.map((item) => {
        return {
          ...item,
        }
      });
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getData()
  },
};
</script>
  
  
<style scoped>
  body {
    font-size: 8px;
  }
  
  #judul {
    color: black;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  }
  #alamat {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
  }
  #kop {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 38px;
    padding-right: 19px;
    /* padding-bottom: 28px; */
    border-bottom: 11px double black;
  }
  #halaman {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 113px;
    padding-right: 76px;
    padding-bottom: 28px;
    font-family: "Times New Roman", Times, serif;
  }
  img {
    color: black;
    height: 110px;
  }
  #content {
    color: black;
    text-align: justify;
    font-size: 20px;
  }
  .border-set {
    /* font-size: 20px; */
    border: 2px solid #000 !important;
  }
  

</style>

<style>
@media print {
  @page {
    size: landscape;
  }
}
#pdf-content {
  width: calc(29.7cm - 2cm * 2);
  /* margin: 28pt 32pt 28pt 32pt;
        word-spacing: 1pt;
        word-break: page-break; */
  color: #000 !important;
}
#content-table {
  font-size: 8pt;
  /* border-top: 1pt solid black;
  border-left: 1pt solid black; */
  border-collapse: collapse;
}
#content-table th,
#content-table td {
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  border: 1px solid black;
  padding-left: 3pt;
  padding-right: 3pt;
}
#footer {
  font-size: 8pt;
}
table {
  white-space: normal !important;
}
</style>